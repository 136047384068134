<script>
export default {
  name: "ImageViewer",

  props: {
    src: {
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 28,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    test() {
      //
    },
    toggleDialog() {
      if (!this.src) {
        return;
      }
      this.dialog = !this.dialog;
    },
  },
};
</script>

<template>
  <div>
    <v-avatar
      style="border: 1px solid"
      :size="size"
      :tile="!rounded"
      :class="{ 'cursor-pointer': src }"
      :color="src ? 'primary' : 'grey'"
    >
      <v-img @click="toggleDialog()" v-if="src" :src="src" alt="Image"></v-img>
      <v-icon v-else @click="toggleDialog()" size="18" disabled>
        mdi-camera
      </v-icon>
    </v-avatar>
    <v-dialog width="800" v-model="dialog">
      <v-card>
        <v-img :src="src">
          <v-btn
            @click="toggleDialog()"
            color="primary"
            absolute
            top
            right
            small
            class="mt-8"
            fab
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped></style>
